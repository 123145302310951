import Cookies from 'js-cookie';

export const tokenName = 'access_token_cookie';

export const setAuthToken = token => {
  Cookies.set(tokenName, token, { domain: ".pingproxies.com" });
};

export const getAuthToken = () => {
  const token = Cookies.get(tokenName);
  return token;
};

export const removeAuthToken = () => Cookies.remove(tokenName, { domain: ".pingproxies.com" });

export const extractToltReferralUUID = () => {
  function getCookieValue(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      // eslint-disable-next-line
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  }

  // UUID regex pattern
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  const cookieValue = getCookieValue("tolt_referral");

  if (cookieValue && uuidPattern.test(cookieValue)) {
    return cookieValue;
  }
  return null;
}
